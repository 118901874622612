import { Divider, Dropdown, Grid, Icon, Label, Menu, Popup } from "semantic-ui-react";
import { OrganizationData, UserClientData } from "../types";
import React, { useContext } from "react";

import { IIQContext } from "./IIQContextProvider";
import { Link } from "react-router-dom";
import { PortfolioContext } from "./PortfolioProvider";
import config from "../config";
import logo from "../img/whiteIIQlogo.png";
import { replaceSpecialCharacters } from "../services/Lib";
import { useAuth0 } from "@auth0/auth0-react";
import useCanAccessFeature from "../hooks/useCanAccessFeature";

const NavBar: React.FC = () => {
    const { user, logout, isAuthenticated } = useAuth0();
    const { documentApprovalCount, loading, clientOptions, unreadNotifications } = useContext(PortfolioContext);
    const { appData, userOrganizations, litcoAuthorizedOrganization, setAppData } = useContext(IIQContext);
    const organizationAccess = useCanAccessFeature("Organization");
    const portfolioAccess = useCanAccessFeature("Portfolio");
    const closelyHeldBusinessAccess = useCanAccessFeature("CloselyHeldBusinesses");
    if (!isAuthenticated) {
        return <p>Please wait...</p>;
    }

    let guideLink = "";
    let isManaged = false;
    let remoteDeposit = false;
    let hasCloselyHeldBusiness = false;
    let hasWillFile = false;
    let hasTrustServices = false;

    if (appData) {
        const {
            IsManaged,
            RemoteDeposit,
            HasCloselyHeldBusiness,
            HasWillFile,
            UserHasWillFile,
            UserHasCloselyHeldBusiness,
            UserHasTrustServices
        } = appData.organization;
        isManaged = IsManaged;
        remoteDeposit = RemoteDeposit;
        if (isManaged) {
            guideLink = config.linkToManagedGuidePDF;
        } else {
            guideLink = config.linkToStandardGuidePDF;
        }
        hasCloselyHeldBusiness = HasCloselyHeldBusiness && UserHasCloselyHeldBusiness ? true : false;
        hasWillFile = HasWillFile && UserHasWillFile ? true : false;
        hasTrustServices = UserHasTrustServices;
    }

    const dropdownItems = [];
    if (hasTrustServices) {
        dropdownItems.push({
            as: "a",
            content: "Portfolio Summary",
            key: "portfolio"
        });
        dropdownItems.push({
            as: "a",
            content: "Document Management",
            key: "document-management"
        });
        dropdownItems.push({
            as: "a",
            content: "Carrier Summary",
            key: "carrier"
        });
        dropdownItems.push({
            as: "a",
            content: "Search",
            key: "search"
        });
    }
    if (portfolioAccess && hasTrustServices) {
        dropdownItems.push({
            as: "a",
            content: "Reports",
            key: "reports"
        });
    }
    if (hasCloselyHeldBusiness && closelyHeldBusinessAccess) {
        dropdownItems.push({
            as: "a",
            content: "Closely Held Business",
            key: "closely-held-business"
        });
    }
    if (!isManaged && portfolioAccess && hasTrustServices) {
        dropdownItems.push(
            {
                as: "a",
                content: "Trust Onboarding",
                key: "onboarding"
            },
            {
                as: "a",
                content: "Admin Console",
                key: "admin-console"
            }
        );
    }
    if (
        isManaged &&
        litcoAuthorizedOrganization && // only LITCO can view multiple
        hasTrustServices
    ) {
        dropdownItems.push(
            {
                as: "a",
                content: "Death Claim Queue",
                key: "death-claim-queue"
            },
            {
                as: "a",
                content: "Tax Return Queue",
                key: "tax-queue"
            }
        );
    }
    if (hasWillFile) {
        dropdownItems.push({
            as: "a",
            content: "Will",
            key: "will"
        });
    }

    const updateSelectedOrganization = (selectedOrganization: OrganizationData) => {
        const {
            OrganizationID,
            CommonName,
            IsManaged,
            AdminConsole,
            RemoteDeposit,
            RoleName,
            Clients,
            SAMLLogoutUrl,
            HasCloselyHeldBusiness,
            HasWillFile,
            UserHasWillFile,
            UserHasCloselyHeldBusiness,
            UserHasTrustServices
        } = selectedOrganization;
        setAppData({
            clients: JSON.parse(Clients) as UserClientData[],
            organization: {
                OrganizationID,
                CommonName,
                IsManaged,
                AdminConsole,
                RemoteDeposit,
                SAMLLogoutUrl,
                HasCloselyHeldBusiness,
                HasWillFile,
                UserHasWillFile,
                UserHasCloselyHeldBusiness,
                UserHasTrustServices
            },
            role: RoleName
        });
    };

    return (
        <Menu fixed="top" size="massive" color="blue" inverted>
            <Menu.Item>
                <img style={{ width: "80%" }} src={logo} alt="ITM DBA Insurance Trust Monitor" />
            </Menu.Item>
            <>
                <Dropdown item text="Portfolio Menu">
                    <Dropdown.Menu>
                        {dropdownItems.map((item) => (
                            <>
                                {item.content === "Death Claim Queue" && <Dropdown.Divider />}
                                <Dropdown.Item as={Link} to={`/${item.key}`} key={item.key}>
                                    {item.content}
                                </Dropdown.Item>
                            </>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
                {(organizationAccess || portfolioAccess) && (hasTrustServices || hasCloselyHeldBusiness) && (
                    <Dropdown item text={replaceSpecialCharacters(appData?.organization.CommonName ?? "Organization")}>
                        <Dropdown.Menu>
                            {organizationAccess && hasTrustServices && (
                                <>
                                    <Dropdown.Item as={Link} to="/organization" key={0}>
                                        Organization Summary
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                </>
                            )}
                            {isManaged && remoteDeposit && hasTrustServices && (
                                <>
                                    <Dropdown.Item as={Link} to="/deposits" key={0}>
                                        Deposits
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                </>
                            )}
                            {(hasCloselyHeldBusiness || (portfolioAccess && clientOptions)) && (
                                <>
                                    <Dropdown.Header content="Portfolios" />
                                    <Dropdown.Divider />
                                    {clientOptions.map((client) => (
                                        <Dropdown.Item as={Link} to={`/portfolio/${client.value}`} key={client.key as number}>
                                            {client.text}
                                        </Dropdown.Item>
                                    ))}
                                </>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                )}
                {hasTrustServices && (
                    <Menu.Item as={Link} to="/document-approval">
                        Document Approval
                        {!loading && (
                            <Label size="medium" basic color="blue" style={{ marginLeft: ".25em" }}>
                                {documentApprovalCount}
                            </Label>
                        )}
                    </Menu.Item>
                )}
                <Menu.Menu position="right">
                    {userOrganizations && userOrganizations.length > 1 && (
                        <Dropdown item text="Select Client">
                            <Dropdown.Menu>
                                {userOrganizations.map((item) => (
                                    <Dropdown.Item
                                        key={item.OrganizationID}
                                        as={Link}
                                        to="/portfolio"
                                        onClick={() => updateSelectedOrganization(item)}
                                    >
                                        {item.CommonName}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                    <Menu.Item as={Link} to="/notifications">
                        <Icon name="mail" />
                        {!loading && unreadNotifications > 0 && (
                            <Label size="medium" basic color="blue" style={{ marginLeft: ".25em" }}>
                                {unreadNotifications}
                            </Label>
                        )}
                    </Menu.Item>
                    <Popup
                        on="click"
                        position="bottom center"
                        header="Support"
                        content={
                            <>
                                <Grid>
                                    <Grid.Row columns={1} verticalAlign="middle">
                                        <Grid.Column style={{ display: "block", marginTop: 15 }}>
                                            <Icon name="call" /> {config.supportPhoneNumber}
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Icon name="mail" /> {config.supportEmailAddress}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <a target="_blank" rel="noopener noreferrer" href={guideLink} style={{ display: "block", marginTop: 15 }}>
                                    Guide
                                </a>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={config.supportLinkToQuestionsPDF}
                                    style={{ display: "block", marginTop: 15 }}
                                >
                                    Questions?
                                </a>
                                <Divider />
                                <Grid inverted>
                                    <Grid.Row columns={1} verticalAlign="middle">
                                        <Grid.Column>Your username: {user?.email}</Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </>
                        }
                        trigger={
                            <Menu.Item name="Help">
                                <Icon name="help circle" />
                            </Menu.Item>
                        }
                    />
                    <Menu.Item onClick={() => logout({ returnTo: appData?.organization.SAMLLogoutUrl ?? undefined })} content="Logout" />
                </Menu.Menu>
            </>
        </Menu>
    );
};

export default NavBar;
