import { OrganizationData, Role, UserClientData, UserOrganizationData } from "../types";
import { createContext, useEffect, useState } from "react";

import React from "react";
import UserDataErrorFallback from "./UserDataErrorFallback";
import config from "../config";
import { useAuth0 } from "@auth0/auth0-react";
import useSproc from "../hooks/useSproc";

type IIQAppData = {
    organization: UserOrganizationData;
    clients: UserClientData[];
    role: Role;
};

interface IIQContextProps {
    appData?: IIQAppData;
    userOrganizations?: OrganizationData[];
    litcoAuthorizedOrganization?: boolean;
    setAppData: (arg0: IIQAppData) => void;
}

export const IIQContext = createContext<IIQContextProps>({
    setAppData: () => {
        throw new Error("Not implemented");
    }
});

interface IIQContextProviderProps {
    children: React.ReactNode;
}

const { litcoOrganizationID } = config;

const IIQContextProvider: React.FC<IIQContextProviderProps> = ({ children }) => {
    const [appData, setAppData] = useState<IIQAppData>();
    const [userOrganizations, setUserOrganizations] = useState<OrganizationData[]>();
    const [litcoAuthorizedOrganization, setLitcoAuthorizedOrganization] = useState<boolean>(false);
    const [userOrganizationError, setUserOrganizationError] = useState<boolean>(false);

    const sproc = useSproc();
    const { isAuthenticated } = useAuth0();

    useEffect(() => {
        if (!isAuthenticated) {
            setAppData(undefined);
            return;
        }
        (async () => {
            try {
                const userOrganizationsData = await sproc<OrganizationData>("GetUserOrganizationData");
                if (!userOrganizationsData) {
                    setUserOrganizationError(true);
                    return;
                }
                const {
                    OrganizationID,
                    CommonName,
                    IsManaged,
                    AdminConsole,
                    RemoteDeposit,
                    RoleName,
                    Clients,
                    SAMLLogoutUrl,
                    HasCloselyHeldBusiness,
                    HasWillFile,
                    UserHasWillFile,
                    UserHasCloselyHeldBusiness,
                    UserHasTrustServices
                } = userOrganizationsData[0];

                setAppData({
                    clients: JSON.parse(Clients) as UserClientData[],
                    organization: {
                        OrganizationID,
                        CommonName,
                        IsManaged,
                        AdminConsole,
                        RemoteDeposit,
                        SAMLLogoutUrl,
                        HasCloselyHeldBusiness,
                        HasWillFile,
                        UserHasWillFile,
                        UserHasCloselyHeldBusiness,
                        UserHasTrustServices
                    },
                    role: RoleName
                });
                setUserOrganizations(userOrganizationsData);
                if (OrganizationID === litcoOrganizationID || userOrganizationsData.some((o) => o.OrganizationID === litcoOrganizationID)) {
                    setLitcoAuthorizedOrganization(true);
                } else {
                    setLitcoAuthorizedOrganization(false);
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, [isAuthenticated, sproc]);

    if (userOrganizationError) {
        return <UserDataErrorFallback />;
    }
    return (
        <IIQContext.Provider value={{ appData, userOrganizations, litcoAuthorizedOrganization, setAppData }}>
            {children}
        </IIQContext.Provider>
    );
};

export default IIQContextProvider;
